import * as model from '../model.js';
//for polyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import loginView from '../views/loginView.js';

//Controller for login page
const controlLogin = async function (newLogin) {
  try {
    // Render the spinner while waiting for authentication
    loginView.renderSpinner();

    // 2) Authenticate with jwt
    await model.login(newLogin);
  } catch (err) {
    console.log(err);
    loginView.renderError(); //display login error message if authentication fails
  }
};

// Initialization of the Controller functions to subscribe to View Event Listener
const init = function () {
  loginView.addHandlerLogin(controlLogin);
  loginView.addHandlerReceiveToken();
  loginView.addHandlerRemoveErrorMessage();
  loginView.addHandlerTogglePassword();
};

init();
